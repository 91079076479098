<template>
  <div id="app">
    <header>
      <div class="box box_con">
        <h1>
          <router-link to="/">
            <img :src="info.logo" :alt="info.title"/>
          </router-link>
        </h1>
        <nav>
          <ul class="box">
            <li v-for="items in meun" :key="items.id">
              <router-link :to="'/'+items.name" v-if="items.id == 1">{{ items.title }}</router-link>
              <router-link :to="'/'+items.name+'?id='+items.url+'&cid='+items.son" v-else-if="items.url ==9 || items.url ==56 || items.url ==57 ||items.url ==65 || items.url ==80 || items.url ==95">{{ items.title }}</router-link>
              <router-link :to="'/'+items.name+'?id='+items.url" v-else>{{ items.title }}</router-link>
            </li>
        
            <li>
              <img src="/static/images/search.png" alt="" @click="showForm = !showForm">
              <transition name="fade">
                <el-form :inline="true" class="demo-form-inline" v-if="showForm" @submit.native.prevent>
                  <el-form-item label="">
                    <el-input v-model="keywords" placeholder="请输入关键词" @keyup.enter="search"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                  </el-form-item>
                </el-form>
              </transition>
            </li>
          </ul>
        </nav>
      </div>
      
    </header>

    <router-view/>

    <!-- footer -->
    <footer>
      <div class="box box_con">
        <div class="foot_logo">
          <img :src="info.yclogo" :alt="info.title">
        </div>
        <div class="foot_txt">
          <p>咨询：{{ info.tel }}</p>
          <p>地址：{{ info.address }}</p>
          <p>{{ info.copyright }}  <a :href="info.filing" target="_blank">{{ info.icp }}</a></p>
        </div>
        <div class="foot_info box">
          <p>关注我们了解更多企业信息</p>
          <p>
            <span><img :src="info.wechat" :alt="info.title"></span>
            <em>融创服务<br/>微信公众号</em>
          </p>
          <p>
            <span><img :src="info.wevideo" :alt="info.title"></span>
            <em>融创服务<br/>视频号</em>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: 'HomeView',
  data: () => ({
    title: document.title,
    home_title:'',
    web_status:1,
    keywords:'',
    metaData:'',
    currentPage: '', // 当前页面的URL路径
    meun:{},
    info: {},
    showForm: false,
    params: {
      page: 1,
      pagesize: 2,
    },
    total: 0,
  }),
  metaInfo() {
    return {
      title: this.$route.meta.title +' - '+ this.home_title,
    };
  },
  methods: {
    getData() {
      Axios.get("/Meun")
        .then((res) => {
          if (res.data.code == 200) {
            this.meun = res.data.data;
          } else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Axios.get("/Piconfig")
        .then((res) => {
          if (res.data.code == 200) {
            this.info = res.data.data;
            this.home_title= res.data.data.home_title;
          } else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      if(this.keywords == ""){
        alert('请输入关键词')
        // 强制刷新页面
        window.location.reload();
      }else{
        // 这里你可以根据你的需求来决定跳转的路由
        window.location.href = '/search?keywords=' + this.keywords;
      }
    },
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getData();
    this.$router.beforeEach((to, from, next) => {
      document.title = this.title = to.meta.title;
      next();
    });
  },
}
</script>
<style>
#app {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;;
}
</style>